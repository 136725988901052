@tailwind base;
@tailwind components;
@tailwind utilities;


/* styles.css */
.radio-input {
    display: none;
  }
  
  .radio-label {
    width: 35px;
    height: 35px;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;
    border: 3px solid transparent;
  }
  
  .radio-input:checked + .radio-label {
    border-color: #3a2f2f;
  }